import React, { Component } from 'react';
import './Perma.css';
import Markdown from 'react-markdown'
import * as utils from '../../utils.js'
import cron, {CronJob} from 'cron';

class Perma extends Component {
    constructor(props) {
       super(props);

       this.state = {
           current:null,
           time: {}, 
           seconds: 0 
       }

       this.timer = 0;
       this.startTimer = this.startTimer.bind(this);
       this.countDown = this.countDown.bind(this);
       this.toSeconds = this.toSeconds.bind(this)
       this.getPerma = this.getPerma.bind(this)

      this.job = new CronJob('1 0 9-18 * * *', function() {
        this.getPerma()
      }.bind(this), null, true, 'Europe/Brussels');

      this.job.start()
      this.getPerma()
    }

    getPerma(){
        fetch("https://igbot.rave.org/permas")
        .then(res => res.json())
        .then(
            (result) => {
                var c = result.current[0]
                var d = new Date()
                console.log(this.state.current)
                this.setState({current: c})
                this.setState({seconds: this.toSeconds(c.finish) - this.toSeconds(`${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`) })
                this.startTimer()
            }
        )
        .catch(err => {
            console.log(err);
          })
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
      }
    
      componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
      }
    
      startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
      }
    
      countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
          clearInterval(this.timer);
          this.setState({})
        }
      }

      toSeconds(time_str) {
        // Extract hours, minutes and seconds
        var parts = time_str.split(':');
        // compute  and return total seconds
        return parts[0] * 3600 + // an hour has 3600 seconds
               parts[1] * 60 +   // a minute has 60 seconds
               +parts[2];        // seconds
    }

    displayTimeLeft(duration) {
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours   = hours.toString().leftpad(2, "0")
        minutes = minutes.toString().leftpad(2, "0")
        seconds = seconds.toString().leftpad(2, "0")

        return `${hours}:${minutes}:${seconds}`;
    }

    render() {
        if(this.state.current)
            return (
                <div className="permaMain">
                   <div className="permaHeader">
                        <h2>{`Perma - ${this.state.current.name}`}</h2>
                        <p>{`${this.state.time.h}:${this.state.time.m}:${this.state.time.s}`}</p>
                    </div>
                </div>
            );
        else return null;
    }

}

export default Perma