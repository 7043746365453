import React, { Component } from 'react';
import './App.css';
import Clock from "./components/Clock/Clock";
import Announcement from "./components/Fullscreen/Announcement/Announcement";
import MattermostConnect from "./MattermostConnect/MattermostConnect";
import Perma from "./components/Perma/Perma"
import config from "./config";
import PcDown from "./components/Fullscreen/PcDown/PcDown";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundImage: "",

            announcement: null,
            timeoutLeft: 0,

            pcDown: null,

            perma: null
        };

        this.timeout = undefined;
        this.interval = undefined;

        this.mattermost = new MattermostConnect();

        this.root = React.createRef();
    }

    componentDidMount() {
        this.mattermost.connect();
        this.mattermost.on('bg', bg => this.setState({backgroundImage: bg}));
        this.mattermost.on('announcement', announcement => this.setAnnouncement(announcement));
        this.mattermost.on('pcdown', pcDown => this.setPcDown(pcDown));
    }

    style = () => ({
        content: "",
        backgroundImage: `url("${this.state.backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        WebkitBackgroundSize: "cover",
        backgroundSize: "cover",
        opacity: 0.6,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        zIndex: -1
    });

    setPcDown = pcDown => {
        if(pcDown.fixed)
            this.setState({
                pcDown: null
            });
        else
            this.setState({
                pcDown: pcDown
            });
    };

    clearAnnouncement = () => {
        this.setState({announcement: null});
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    };

    setAnnouncement = announcement => {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
        this.setState({announcement: announcement});
        this.timeout = setTimeout(
            () => this.clearAnnouncement(),
            config.announcementTimeout
        );
        this.setState({
            timeoutLeft: config.announcementTimeout
        });
        this.interval = setInterval(
            () => this.setState(prevState => ({
                timeoutLeft: prevState.timeoutLeft - 1000
            })),
            1000
        )
    };

    render() {
        return (
            <div className="App">
                <div style={this.style()}/>
                <div className="centralize">
                    {
                        this.state.pcDown
                            ?<PcDown pcDown={this.state.pcDown} />
                            :this.state.announcement
                                ?<Announcement timeoutLeft={this.state.timeoutLeft} announcement={this.state.announcement}/>
                                :<Clock mattermost={this.mattermost}>
                                    <Perma/> 
                                 </Clock> 
                                
                    }
                </div>
            </div>
        );
    }
}

export default App;
