import React, { Component } from 'react';

class HourPointer extends Component {

	style = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '50%',

		backgroundColor: 'white',
		width: '1.2%',
		height: '20%',
		transformOrigin: '50% 100%',
		transform: `translateX(-50%) rotate(${this.props.value/(12*60*60*1000) * 360}deg)`
	});

	render() {
		return (
			<div style={this.style()} />
		);
	}
}

class MinutePointer extends Component {
	style = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '50%',

		backgroundColor: 'lightgray',
		width: '1%',
		height: '30%',
		transformOrigin: '50% 100%',
		transform: `translateX(-50%) rotate(${this.props.value/(60*60*1000) * 360}deg)`
	});

	render() {
		return (
			<div style={this.style()} />
		);
	}
}

class SecondPointer extends Component {
	style = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '40%',

		backgroundColor: 'red',
		width: '0.5%',
		height: '50%',
		transformOrigin: '50% 80%',
		transform: `translateX(-50%) rotate(${this.props.value/(60*1000) * 360}deg)`
	});

	render() {
		return (
			<div style={this.style()} />
		);
	}
}

class Pointers extends Component {
	render() {
		const secondsToMillis = this.props.date.getSeconds() * 1000           + (this.props.smooth ? this.props.date.getMilliseconds() : 0);
		const minutesToMillis = this.props.date.getMinutes() * 60 * 1000      + secondsToMillis;
		const hoursToMillis = this.props.date.getHours()     * 60 * 60 * 1000 + minutesToMillis + secondsToMillis;
		return (
			<div>
				<HourPointer   value={hoursToMillis}/>
				<MinutePointer value={minutesToMillis}/>
				<SecondPointer value={secondsToMillis}/>
				<Dot/>
			</div>
		);
	}
}

class Number extends Component {
	style = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '50%',
		height: '45%',
		transformOrigin: '50% 100%',
		textAlign: 'center',
		color: 'white',
		transform: `translateX(-50%) rotate(${this.props.value/12 * 360}deg)`,
		fontSize: '5rem'
	});

	render() {
		return (
			<span style={this.style()}>
				{this.props.value}
			</span>
		);
	}
}

class Indicator extends Component {
	outerStyle = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '50%',
		height: '50%',
		width: '1%',
		transformOrigin: '50% 100%',
		transform: `translateX(-50%) rotate(${this.props.value/60 * 360}deg)`
	});

	innerStyle = () => ({
		marginTop: '1rem',
		width: this.props.value % 5 === 0 ? '75%' : '25%',
		height: this.props.value % 5 === 0 ? '5%' : '2%',
		backgroundColor: 'white'
	});

	render() {
		return (
			<div style={this.outerStyle()}>
				<div style={this.innerStyle()} />
			</div>
		);
	}
}

class Indicators extends Component {
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return false;
	}

	render() {
		return Array.from(Array(60))
			.map((_, index) => <Indicator key={index} value={index} />)
	}
}

class ClockBackground extends Component {

	style = () => ({
		position: 'relative',

		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		width: '90vh',
		height: '90vh',
		borderRadius: '100%'
	});

	render() {
		return (
			<div style={this.style()}>
				{this.props.children}
			</div>
		);
	}
}

class Dot extends Component {
	style = () => ({
		position: 'absolute',
		left: '50%',
		bottom: '50%',
		width: '2%',
		height: '2%',
		transform: `translateX(-50%) translateY(50%)`,
		borderRadius: '100%',
		backgroundColor: 'red',
	});

	render() {
		return (
			<div style={this.style()} />
		);
	}
}

class AnalogClock extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
			<ClockBackground>
				<Indicators/>
				{[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(n => <Number value={n}/>)}
				<Pointers date={this.props.date} smooth={this.props.smooth}/>
			</ClockBackground>
        );
    }
}

export default AnalogClock
