String.prototype.leftpad = function(len, ch) {
  let str = this

  let i = -1;

  if (!ch && ch !== 0) ch = ' ';

  len = len - str.length;

  while (++i < len) {
    str = ch + str;
  }

  return str;
}

export function dateFormat(date) {
   let hours = date.getHours().toString().leftpad(2, "0");
   let minutes = date.getMinutes().toString().leftpad(2, "0");
   let seconds = date.getSeconds().toString().leftpad(2, "0");
   return `${hours}:${minutes}:${seconds}`;
}
