import React, { Component } from 'react';
import './Announcement.css';
import '../Fullscreen.css';
import Markdown from 'react-markdown'
import * as utils from '../../../utils.js'

class Announcement extends Component {
    constructor(props) {
       super(props);

       this.state = {}
       setInterval(() => {
          let date = new Date();
          let date_formatted = utils.dateFormat(date)
          this.setState({currentDate: date_formatted}) 
       }, 1000)
    }

    displayTimeLeft(duration) {
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours   = hours.toString().leftpad(2, "0")
        minutes = minutes.toString().leftpad(2, "0")
        seconds = seconds.toString().leftpad(2, "0")

        return `${hours}:${minutes}:${seconds}`;
    }

    render() {
        if(this.props.announcement)
            return (
                <div className="fullscreenMain">
                   <div className="fullscreenHeader announcementHeader">
                        <p>{this.state.currentDate}</p>
                        <h2>{`Announcement by @${this.props.announcement.user_name}`}</h2>
                        <p>{this.displayTimeLeft(this.props.timeoutLeft)}</p>
                    </div>
                    <Markdown className="fullscreenContent">{this.props.announcement.text}</Markdown>
                </div>
            );
        else return null;
    }

}

export default Announcement
