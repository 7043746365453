import React, { Component } from 'react';
import AnalogClock from './AnalogClock'
import './Clock.css'
import DigitalClock from "./DigitalClock";

class Clock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            digital: false,
			smooth: false,
			debug: false
        };

        this.daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        this.months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        this.timeInterval = null
    }

    componentDidMount() {
        this.changeSmoothness(false);
        this.props.mattermost.on('digital', digital => this.setState({digital: digital}));
		this.props.mattermost.on('smooth', smooth => this.changeSmoothness(smooth));
    }

    changeSmoothness = smooth => {
    	if(this.timeInterval)
    		clearInterval(this.timeInterval);
    	this.setState(
    		{smooth: smooth},
			() => this.timeInterval = setInterval(
				() => this.setState({date: new Date()}),
				this.state.smooth ? 16 : 1000
			)
		)
	};

    render() {
        const date = this.state.date;
        return (
            <div className="clockFlex">
                <div className="clockHeaderContainer">
                    {
                        this.state.digital
                            ?<DigitalClock date={this.state.date} />
							:<AnalogClock date={this.state.date} smooth={this.state.smooth}/>
                    }
                </div>
                <div>
                    <span className="dateHeader">
                        {`${this.daysOfWeek[date.getDay()]}, ${date.getDate()} ${this.months[date.getMonth()]} ${date.getFullYear()}`}
                    </span>
                </div>
				{this.state.debug && (
					<div>
						<button onClick={() => this.changeSmoothness(!this.state.smooth)}>Toggle smoothness</button>
						<button onClick={() => this.setState(prevState => ({digital: !prevState.digital}))}>Toggle digital</button>
					</div>
				)}

                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

export default Clock;
