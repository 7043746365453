import React, { Component } from 'react';

class DigitalClock extends Component {
	render() {
		const date = this.props.date;
		return (
			<div>
                <span className="clockHeader clockHeaderMain">
                        {`${date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}`}
                    </span>
				<span className="clockHeader clockHeaderSeconds">
                        {`:${date.getSeconds().toString().length === 1 ? `0${date.getSeconds()}` : date.getSeconds()}`}
                    </span>
			</div>
		);
	}
}

export default DigitalClock
