import React, { Component } from 'react';
import './PcDown.css';
import '../Fullscreen.css';

class PcDown extends Component {
    render() {
        return (
            <div className="fullscreenMain">
                <div className="fullscreenHeader pcDownHeader">
                    <h2>{`PC ${this.props.pcDown.pc} in room ${this.props.pcDown.room} is down! Go fix it immediately!`}</h2>
                </div>
                {this.props.pcDown.map}
            </div>
        );
    }
}

export default PcDown