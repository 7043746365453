import io from 'socket.io-client';
import config from '../config'

class MattermostConnect {
    constructor() {
        this.table = {};
    }

    connect = () => {
        fetch(config.socketServer)
            .then(ok => this.loadSocketIO())
            .catch(err => console.error(err));
    };

    loadSocketIO = () => {
        let scriptTag = document.createElement('script');
        scriptTag.src = `${config.socketServer}socket.io/socket.io.js`;
        scriptTag.onload = this.startSocketIO;
        document.querySelector("head").appendChild(scriptTag)
    };

    startSocketIO = () => {
        const socket = io.connect(config.socketServer);
        socket.on('msg', data => this.table[data.type](data.value));
    };

    on = (key, fn) => this.table[key] = fn;
}

export default MattermostConnect